import React, {useState} from 'react'
import {Bars3BottomLeftIcon,} from '@heroicons/react/24/outline'
import {PlusIcon,} from '@heroicons/react/20/solid'
import Sidebar from "./Sidebar";
import useAuth from "../../hooks/auth/useAuth";
import {Outlet} from "react-router-dom";
import DataStorage from "../../providers/core/cassie/IndexedDb/DataStorage";
import {DropMenu, DropMenuItem} from "../controls/vidence/menus/dropmenu/Dropmenu";

export default function Layout(props: { children: any }) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const {getIdentity, logout} = useAuth();
    const identity = getIdentity();

    const identityImage = `https://${identity}/pub/image`;

    const handleLogout = async () => {
        logout().then(() => {
            return DataStorage.getInstance().dbInstance.delete();
        })
    }

    const buildTimestamp = process.env.REACT_APP_BUILD_DATE;
    return (
        <>
            <div className="flex h-full dark:bg-slate-800 bg-white">
                <Sidebar onMobileMenuClose={setMobileMenuOpen} mobileMenuOpen={mobileMenuOpen}/>

                <div className="flex flex-1 flex-col h-full overflow-hidden">
                    <header className="w-full">
                        <div className="relative z-7 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white shadow-sm">
                            <button
                                type="button"
                                className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                                onClick={() => setMobileMenuOpen(true)}>
                                <span className="sr-only">Open sidebar</span>
                                <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true"/>
                            </button>
                            <div className="flex flex-1 justify-between px-4 sm:px-6">
                                <div className="flex flex-1">
                                    <div className="flex items-center w-full text-gray-400 focus-within:text-gray-600">
                                        Guideflow
                                    </div>
                                </div>
                                <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
                                    <DropMenu imageElement={<img
                                        className="h-8 w-8 rounded-full"
                                        src={identityImage}
                                        alt=""
                                        title={identity}/>}>
                                        
                                        <div className="px-3 mb-2 text-gray-400 text-xs">{identity}</div>
                                        <hr className="mt-1 mb-2"/>
                                        {buildTimestamp && <DropMenuItem title={`Build: ${buildTimestamp}`}/>}
                                        <DropMenuItem title="Settings" to={"/settings"}/>
                                        <hr className="mt-1 mb-2"/>
                                        <DropMenuItem title="Logout" onClick={handleLogout}/>
                                        
                                    </DropMenu>

                                    <DropMenu
                                        menuButtonClassName="p-1.5 hover:bg-indigo-500 text-white relative rounded-full bg-indigo-600  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        imageElement={<PlusIcon className="h-5 w-5" aria-hidden="true"/>}>
                                        <DropMenuItem title="Add Contact" to="/contacts/new"/>
                                        <DropMenuItem title="Add Healing" to="/healings/new"/>
                                        <DropMenuItem title="Add Class" to="/classes/new"/>
                                    </DropMenu>
                                </div>
                            </div>
                        </div>
                    </header>
                    <Outlet/>
                </div>
            </div>
        </>
    )
}
