import React, {useEffect, useState} from "react";
import {PlusCircleIcon} from "@heroicons/react/16/solid";
import {useNavigate, useParams} from "react-router-dom";
import {ContactProgressionEvent, ContactProgressionEventType} from "../../../../../providers/contact/ContactProgressionEventProvider";
import {useGuideFlowProviderContext} from "../../../../../GuideFlowProviderContext";
import {useFriendlyText} from "../../../../../hooks/friendlyText/useFriendlyText";
import {LoadingPanel} from "../../../../controls/vidence/blanks/LoadingPanel";
import EmptyScreen from "../../../../controls/vidence/blanks/EmptyScreenAction";
import ClassTitle from "../../../../controls/renderers/Classes/ClassTitle";
import HealingTitle from "../../../../controls/renderers/Healings/HealingTitle";
import InitiationTitle from "../../../../controls/renderers/Initiation/InitiationTitle";
import {DropMenu, DropMenuItem} from "../../../../controls/vidence/menus/dropmenu/Dropmenu";
import ConfirmationDialog from "../../../../controls/vidence/modal/ConfirmationDialog";
import toast from "react-hot-toast";

export default function ContactProgressionList() {

    const {id} = useParams();
    const navigate = useNavigate();
    const [entries, setEntries] = useState<ContactProgressionEvent[]>();
    const [entryForDeletion, setEntryForDeletion] = useState<ContactProgressionEvent | null>(null);

    const [loaded, setLoaded] = useState(false);
    const context = useGuideFlowProviderContext();
    const text = useFriendlyText();

    useEffect(() => {
        setLoaded(true);
        context.Contacts.ProgressionEvents.getAll(id, {cassieQueryOptions: {forceServerCall: true}}).then((items) => {
            setLoaded(true);
            setEntries(items);
        });
    }, [id, context.Contacts.ProgressionEvents, context.Contacts.ProgressionEvents.StateChanged]);


    const handleAddEntry = async (type: ContactProgressionEventType) => {
        navigate(`${type}/new`);
    }

    function handleEditEntry(entry: ContactProgressionEvent) {
        navigate(`${entry.definitionType}/edit/${entry.id}`);
    }

    async function handleFinalizeDelete(answer: boolean) {

        setEntryForDeletion(null);
        if (answer) {
            const t = entryForDeletion.definitionType
            const success = await context.Contacts.ProgressionEvents.delete(entryForDeletion.id)
            if (success) {
                setEntries(p => p.filter(e => e.id !== entryForDeletion.id));
                toast.success(`Deleted ${t} event`, {icon: '😔'});
            }
        }
    }

    if (!loaded) {
        return (<LoadingPanel text="loading progression history..."/>);
    }

    if (loaded) {
        return (
            <>
                <ConfirmationDialog
                    isOpen={entryForDeletion !== null}
                    title="Are you sure?"
                    onAnswerReceived={handleFinalizeDelete}>
                    Are you sure you want to delete <b><EventTitle event={entryForDeletion}/></b>
                </ConfirmationDialog>

                <div>
                    <div className="flex h-16 flex-col justify-center">
                        <div className="flex justify-between py-3">
                            {/* Left buttons */}
                            <div className="isolate inline-flex rounded-md shadow-sm sm:space-x-3 sm:shadow-none">
                                  <span className="inline-flex sm:shadow-sm">
                                    <button
                                        type="button"
                                        onClick={() => handleAddEntry("healing")}
                                        className="relative inline-flex rounded-l-md items-center gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10">
                                      <PlusCircleIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/> Healing
                                    </button>
                                      <button
                                          type="button"
                                          onClick={() => handleAddEntry("class")}
                                          className="relative inline-flex items-center gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10">
                                      <PlusCircleIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/> Class
                                    </button>
                                  
                                      <button
                                          type="button"
                                          onClick={() => handleAddEntry("initiation")}
                                          className="relative inline-flex -ml-px rounded-r-md items-center gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10">
                                          <PlusCircleIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/> Initiation
                                      </button>
                                  </span>
                            </div>
                        </div>
                    </div>
                </div>

                {loaded && entries?.length === 0 &&
                    <EmptyScreen text="Looks empty in here... add some progression history"/>}

                {loaded && entries?.length > 0 &&
                    <ul className="divide-y divide-gray-100">
                        {entries.map((entry) => (
                            <li key={entry.id} className="flex items-center justify-between gap-x-3 py-5">
                                <div className="min-w-16">
                                    {/*<Thumbnail document={entry} className="h-16 w-16 border border-gray-300 bg-white text-gray-300"/>*/}
                                </div>
                                <div className="flex flex-row w-full justify-between gap-x-1">
                                    <div className="min-w-0 max-w-80">
                                        <div className="flex items-start gap-x-3">
                                            <p className="truncate text-sm font-semibold leading-6 text-gray-900">
                                                <EventTitle event={entry}/>
                                            </p>
                                        </div>
                                        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                            <p className="whitespace-nowrap">
                                                {entry.eventDateUtcSeconds && text.asShortDateWithYear(entry.eventDateUtcSeconds)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-none items-center gap-x-4">
                                        <DropMenu>
                                            <DropMenuItem title="Edit" onClick={() => {
                                                handleEditEntry(entry);
                                            }}/>
                                            <DropMenuItem title="Remove" onClick={() => {
                                                setEntryForDeletion(entry)
                                            }}/>
                                        </DropMenu>

                                        {/*<button*/}
                                        {/*    onClick={() => {*/}
                                        {/*        handleEditEntry(entry);*/}
                                        {/*    }}*/}
                                        {/*    className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">*/}
                                        {/*    Edit <span className="sr-only"></span>*/}
                                        {/*</button>*/}
                                        {/*<Menu as="div" className="sm:hidden relative flex-none">*/}
                                        {/*    <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">*/}
                                        {/*        <span className="sr-only">Open options</span>*/}
                                        {/*        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true"/>*/}
                                        {/*    </Menu.Button>*/}
                                        {/*    <Transition*/}
                                        {/*        as={Fragment}*/}
                                        {/*        enter="transition ease-out duration-100"*/}
                                        {/*        enterFrom="transform opacity-0 scale-95"*/}
                                        {/*        enterTo="transform opacity-100 scale-100"*/}
                                        {/*        leave="transition ease-in duration-75"*/}
                                        {/*        leaveFrom="transform opacity-100 scale-100"*/}
                                        {/*        leaveTo="transform opacity-0 scale-95">*/}
                                        {/*        <Menu.Items*/}
                                        {/*            className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">*/}
                                        {/*            <Menu.Item>*/}
                                        {/*                {({focus}) => (*/}
                                        {/*                    <button*/}
                                        {/*                        onClick={() => {*/}
                                        {/*                            handleEditEntry(entry);*/}
                                        {/*                        }}*/}
                                        {/*                        className={classNames(focus ? 'bg-gray-50' : '', 'w-full block px-3 py-1 text-sm leading-6 text-gray-900')}>Edit</button>*/}
                                        {/*                )}*/}
                                        {/*            </Menu.Item>*/}
                                        {/*        </Menu.Items>*/}
                                        {/*    </Transition>*/}
                                        {/*</Menu>*/}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                }
            </>)
    }
}

function EventTitle(props: { event: ContactProgressionEvent }) {

    if (!props.event) {
        return <></>;
    }
    switch (props.event.definitionType) {
        case "class":
            return <ClassTitle id={props.event.definitionId}/>
        case "healing":
            return <HealingTitle id={props.event.definitionId}/>
        case "initiation":
            return <InitiationTitle id={props.event.definitionId}/>
    }

}