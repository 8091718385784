import React, {useState} from 'react';
import {useGuideFlowProviderContext} from "../../../GuideFlowProviderContext";
import {ErrorMessage, Field, Label} from "../../controls/tailwindui/fieldset";
import {Input} from "../../controls/tailwindui/input";
import {useForm} from 'react-hook-form';
import {Textarea} from "../../controls/tailwindui/textarea";
import {parseDineroSnapshot} from "../../../helpers/Money";
import {generalConfig} from "../../../system/config";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import {Button} from "../../controls/tailwindui/button";
import SpinnerIcon from "../../controls/icons/SpinnerIcon";
import {emptyHealingDefinition, HealingDefinitionForm, SECTION_ROOT} from "./FormTypes";
import {HealingDefinition} from "../../../providers/definitions/HealingDefinitionProvider";

export default function AddHealingDefinitionView() {
    const context = useGuideFlowProviderContext();
    const {register, reset, handleSubmit, formState: {errors}} = useForm({defaultValues: emptyHealingDefinition});
    const [isSaving, setIsSaving] = useState(false);

    const navigate = useNavigate();

    const onSubmit = async (formData: HealingDefinitionForm) => {

        setIsSaving(true);
        const exchange = parseDineroSnapshot(formData.exchangeRaw);
        delete formData.exchangeRaw;
        const definition: HealingDefinition = {
            ...formData,
            exchange: exchange
        };

        await context.HealingDefinitions.save(definition);
        reset(emptyHealingDefinition);
        toast.success("New healing added", {icon: "🪄"});
        setIsSaving(false);
        navigate(SECTION_ROOT);
    };

    return (
        <div className="p-3">
            <div>
                <h2 className="text-2xl font-bold tracking-tight text-gray-900">New Healing</h2>
                <p className="mt-1 text-sm leading-6 text-gray-400">
                    You can add images and other information later.
                </p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="xl:gap-x-16">
                    <div>
                        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                            <div>
                                <Field>
                                    <Label>Title</Label>
                                    <Input {...register("title", {required: true,})} invalid={errors?.title != null} autoFocus={true}/>
                                    {errors?.title && <ErrorMessage>*{errors.title.message}</ErrorMessage>}
                                </Field>
                            </div>
                            <div className="sm:col-span-2">
                                <Field>
                                    <Label>Description</Label>
                                </Field>
                                <Textarea {...register("description")}/>
                            </div>
                            <div>
                                <Field>
                                    <Label>Duration (minutes)</Label>
                                </Field>
                                <Input type="number"
                                       invalid={errors?.durationMinutes != null}
                                       {...register("durationMinutes", {
                                           required: true,
                                           pattern: /[0-9]/,
                                           setValueAs: v => parseInt(v)
                                       })}/>
                            </div>
                            <div>
                                <Field>
                                    <Label>Exchange</Label>
                                </Field>
                                <Input type="text"
                                       invalid={errors?.exchangeRaw != null}
                                       {...register("exchangeRaw", {
                                           required: true,
                                           pattern: generalConfig.currencyRegEx,
                                       })}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <Button type="button" disabled={isSaving} onClick={() => navigate(SECTION_ROOT)} outline={true}>
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        disabled={isSaving}
                        color="indigo">
                        {isSaving && <SpinnerIcon/>}
                        Save
                    </Button>
                </div>
            </form>
        </div>
    )
}
