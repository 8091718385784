import {EmbeddedThumb, PayloadDescriptor} from "@youfoundation/js-lib/dist/core/DriveData/File/DriveFileTypes";
import {TargetDrive} from "@youfoundation/js-lib/core";

export const ARCHIVAL_STATUS_NONE_SPECIFIED = 0;
export const ARCHIVAL_STATUS_DELETED: number = 2;

export interface HomebaseStorageInfo {
    fileId?: string,
    versionTag?: string
    created?: number;
    updated?: number;

    uniqueId?: string;
    archivalStatus?: number;
    dataType?: number;
    fileType?: number;

    userDate?: number;

    content?: string;

    previewThumbnail?: EmbeddedThumb;
    payloads?: PayloadDescriptor[];
}

export interface HomebaseStorage {
    id: string //maps to uniqueId in appData
    storage?: HomebaseStorageInfo
}


export interface SaveOptions {
    onVersionConflict?: () => void
    groupId?: string | undefined
    userDate?: number | undefined

    //overrides the filetype from the provider
    overrideDataType?: number;

    tags?: string[];
    previewThumbnail?: EmbeddedThumb;
}

export interface SaveFileConfig {
    fileType: number,
    dataType: number,
    targetDrive: TargetDrive
}

export interface ImageHeader {
    mimeType: string,
    width: number,
    height: number,
}

export interface Image64Data extends ImageHeader {
    base64Data: string
}
