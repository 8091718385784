import {CheckCircleIcon, UserIcon} from '@heroicons/react/20/solid'
import React, {useEffect, useState} from "react";
import {useGuideFlowProviderContext} from "../../../GuideFlowProviderContext";
import {LocationDefinition} from "../../../providers/definitions/LocationDefinitionProvider";
import {normalizeGuid} from "../../../providers/DataHelpers";
import {LoadingPanel} from "../vidence/blanks/LoadingPanel";
import {Dialog, DialogActions, DialogBody, DialogTitle} from "../tailwindui/dialog";
import {Description} from "../tailwindui/fieldset";
import {Button} from "../tailwindui/button";

interface LocationSelectorProps {
    selectedLocationId?: string,
    readonly?: boolean,
    onSaveSelection: (selection: LocationDefinition) => void
}

export default function LocationSelector(props: LocationSelectorProps) {

    const context = useGuideFlowProviderContext();
    const [allLocations, setAllLocations] = useState<Record<string, LocationDefinition[]>>();
    const [loading, setLoading] = useState(true);
    const [showDialog, setShowDialog] = useState(false);
    const [selection, setSelection] = useState<LocationDefinition>();

    useEffect(() => {
        context.Locations.getAllGrouped().then(locations => {
            setAllLocations(locations);
            if (props.selectedLocationId) {
                context.Locations.get(props.selectedLocationId).then(loc => {
                    setSelection(loc);
                    setLoading(false);
                });
            } else {
                setLoading(false);
            }
        })
    }, [context.Locations, context.Locations.StateChanged, props]);

    const handleDialogClose = () => {
        setShowDialog(false);
    }

    const isSelected = (definition: LocationDefinition) => {
        if (selection) {
            return normalizeGuid(selection.id) === normalizeGuid(definition.id);
        }
    }

    const handleSaveFinalSelection = () => {
        props.onSaveSelection(selection);
        setShowDialog(false);
    }

    const handleCancel = () => {
        setShowDialog(false);
    }

    const handleDefinitionOnChange = (event: any, definition: LocationDefinition) => {
        const isSelected = selection !== null;
        if (isSelected) {
            setSelection(definition);
        }
    }

    const hasDialogSelection = selection != null

    if (loading) {
        return <LoadingPanel text={"loading locations"}/>
    }

    return (
        <>
            <Dialog
                size="lg"
                onClose={handleDialogClose}
                initialFocus={undefined}
                open={showDialog}>

                <DialogTitle>
                    <div className="flex flex-row gap-3 justify-center">
                        <UserIcon className="h-6 w-6" aria-hidden="true"/>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">
                            Locations
                        </h3>
                    </div>
                </DialogTitle>
                <DialogBody className="text-sm/6 text-zinc-900 dark:text-white h-96 overflow-y-scroll border-b">
                    {Object.keys(allLocations).map((letter) => (
                        <div key={letter} className="relative border ">
                            <div className="sticky top-0 z-10 border-b border-t border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
                                <h3>{letter}</h3>
                            </div>
                            <ul className="relative z-0 divide-y divide-gray-200">
                                {allLocations[letter].map((definition) => (
                                    <div key={definition.id} onClick={(event) => handleDefinitionOnChange(event, definition)}
                                         className="relative flex items-center space-x-3 px-6 py-5 hover:bg-gray-50">
                                        {isSelected(definition) && <CheckCircleIcon className="h-5 w-5"/>}
                                        {!isSelected(definition) &&
                                            <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                                <path
                                                    d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                                                    stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>}
                                        <div className="w-full">
                                            <span>{definition.title}</span>
                                            <Description>{definition.address}</Description>
                                        </div>
                                    </div>
                                ))}
                            </ul>
                        </div>
                    ))}
                </DialogBody>
                <DialogActions className="w-full">
                    <div>
                        <Button
                            type="button"
                            disabled={!hasDialogSelection}
                            onClick={handleSaveFinalSelection}
                            className="mt-1 inline-flex w-full justify-center">
                            Save
                        </Button>
                    </div>
                    <div>
                        <Button
                            type="button"
                            onClick={handleCancel}
                            className="mt-1 inline-flex w-full justify-center">
                            Cancel
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
            <div>
                {hasDialogSelection && <span className="pr-3 ">{selection.title}</span>}
                {!hasDialogSelection && <span className="pr-3 text-gray-600">Select a Location</span>}
                {!props.readonly && <Button type="button" onClick={() => setShowDialog(true)} outline={true}>
                    ...
                </Button>}
            </div>
        </>
    );
}


