import React, {ReactNode, Suspense} from 'react';
import './App.css';

import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

import Layout from "./components/Layout/Layout1";
import {Outlet, Route, BrowserRouter, Routes, Navigate} from "react-router-dom";
import NotFound from "./components/Layout/NotFound";
import Login from "./pages/Login";
import FinalizeLogin from "./pages/FinalizeLogin";
import useAuth from "./hooks/auth/useAuth";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import ContactSectionIndex from "./components/Sections/Contact/ContactSectionIndex";
import ClassesSectionIndex from "./components/Sections/Classes/ClassesSectionIndex";
import HealingsSectionIndex from "./components/Sections/Healings/HealingsSectionIndex";
import ContactOverview from "./components/Sections/Contact/ContactOverview";
import HealingOverview from "./components/Sections/Healings/HealingOverview";
import ContactProfileDetailsTab from "./components/Sections/Contact/Tabs/ContactProfileDetailsTab";
import ContactNotesTab from "./components/Sections/Contact/Tabs/ContactNotesTab";
import ContactEditDetailsScreen from "./components/Sections/Contact/Tabs/Details/ContactEditDetailsScreen";
import ContactDetailsScreen from "./components/Sections/Contact/Tabs/Details/ContactDetailsScreen";
import ContactDocumentsTab from "./components/Sections/Contact/Tabs/ContactDocumentsTab";
import HealingDetailsTab from "./components/Sections/Healings/Tabs/HealingDetailsTab";
import HealingDetailsView from "./components/Sections/Healings/Tabs/Details/HealingDetailsView";
import HealingDetailsEditView from "./components/Sections/Healings/Tabs/Details/HealingDetailsEditView";
import HealingDocumentsTab from "./components/Sections/Healings/Tabs/HealingDocumentsTab";
import {GuideFlowProvider} from "./GuideFlowProviderContext";
import HomeSectionIndex from "./components/Sections/Home/HomeSectionIndex";
import ContactProgressionTab from "./components/Sections/Contact/Tabs/Progression/ContactProgressionTab";
import SettingsSectionIndex from "./components/Sections/Settings/SettingsSectionIndex";
import GeneralSettingsTab from "./components/Sections/Settings/Tabs/GeneralSettingsTab";
import PackageSettingsTab from "./components/Sections/Settings/Tabs/PackageSettingsTab";
import ContactProgressionList from "./components/Sections/Contact/Tabs/Progression/ContactProgressionList";
import ContactProgressionAddEditHealing from "./components/Sections/Contact/Tabs/Progression/ContactProgressionAddEditHealing";
import ContactProgressionAddEditInitiation from "./components/Sections/Contact/Tabs/Progression/ContactProgressionAddEditInitiation";
import ContactProgressionAddEditClass from "./components/Sections/Contact/Tabs/Progression/ContactProgressionAddEditClass";
import ClassOverview from "./components/Sections/Classes/ClassOverview";
import ClassDetailsTab from "./components/Sections/Classes/Tabs/ClassDetailsTab";
import ClassDocumentsTab from "./components/Sections/Classes/Tabs/ClassDocumentsTab";
import ClassHistoryTab from "./components/Sections/Classes/Tabs/ClassHistoryTab";
import ClassDetailsEditView from "./components/Sections/Classes/Tabs/Details/ClassDetailsEditView";
import ClassDetailsView from "./components/Sections/Classes/Tabs/Details/ClassDetailsView";
import ClassSchedulingTab from "./components/Sections/Classes/Tabs/ClassSchedulingTab";
import WelcomeScreen, {INIT_HAS_RUN} from "./WelcomeScreen";
import ContactNurturingTab from "./components/Sections/Contact/Tabs/Nurturing/ContactNurturingTab";
import ContactNurturingInfo from "./components/Sections/Contact/Tabs/Nurturing/ContactNurturingInfo";
import ClassScheduleDetails from "./components/Sections/Classes/Tabs/Schedule/ClassScheduleDetails";
import ClassScheduleList from "./components/Sections/Classes/Tabs/Schedule/ClassScheduleList";
import LocationSettingsTab from "./components/Sections/Settings/Tabs/LocationSettingsTab";
import DataSettingsTab from "./components/Sections/Settings/Tabs/DataSettingsTab";
import {HealingHistoryTab} from "./components/Sections/Healings/Tabs/HealingHistoryTab";
import {isProduction} from "./helpers/misc";

const AUTH_PATH = '/auth';
const queryClient = new QueryClient();

function App() {
    return (
        <HelmetProvider>
            <Helmet>
                {/*<meta name="v" content={import.meta.env.VITE_VERSION}/>*/}
            </Helmet>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <Suspense>
                        <Routes>
                            <Route path="auth" element={<Login/>}></Route>
                            <Route path="auth/finalize" element={<FinalizeLogin/>}></Route>
                            <Route path="" element={<RootRoute><Outlet/></RootRoute>}>
                                <Route path="" element={<Layout><Suspense><Outlet/></Suspense></Layout>}>
                                    <Route path="" element={<Navigate to="home"/>}></Route>
                                    {isProduction() &&
                                        <Route path="home" element={<Navigate to={"/contacts"}/>}/>
                                    }

                                    {!isProduction() &&
                                        <Route path="home" element={<HomeSectionIndex/>}/>
                                    }

                                    <Route path="/contacts" element={<ContactSectionIndex/>}>
                                        <Route path=":id" element={<ContactOverview/>}>
                                            <Route path="overview" element={<ContactProfileDetailsTab/>}>
                                                <Route path="" element={<ContactDetailsScreen/>}/>
                                                <Route path="edit" element={<ContactEditDetailsScreen/>}/>
                                            </Route>
                                            {/*<Route path="bookings" element={<ContactBookingsTab/>}>*/}
                                            {/*    <Route path="" element={<ContactBookingsList/>}/>*/}
                                            {/*    <Route path="new" element={<ContactBookingAddEdit/>}/>*/}
                                            {/*    <Route path="edit/:bookingId" element={<ContactBookingAddEdit/>}/>*/}
                                            {/*</Route>*/}
                                            <Route path="notes" element={<ContactNotesTab/>}/>
                                            <Route path="documents" element={<ContactDocumentsTab/>}/>
                                            <Route path="progression" element={<ContactProgressionTab/>}>
                                                <Route path="" element={<ContactProgressionList/>}/>
                                                <Route path="healing/new" element={<ContactProgressionAddEditHealing/>}/>
                                                <Route path="healing/edit/:progressionId" element={<ContactProgressionAddEditHealing/>}/>
                                                <Route path="class/new" element={<ContactProgressionAddEditClass/>}/>
                                                <Route path="class/edit/:progressionId" element={<ContactProgressionAddEditClass/>}/>
                                                <Route path="initiation/new" element={<ContactProgressionAddEditInitiation/>}/>
                                                <Route path="initiation/edit/:progressionId" element={<ContactProgressionAddEditInitiation/>}/>
                                            </Route>
                                            <Route path="nurturing" element={<ContactNurturingTab/>}>
                                                <Route path="" element={<ContactNurturingInfo/>}/>
                                            </Route>
                                        </Route>
                                    </Route>

                                    {/*<Route path="/schedule" element={<ScheduleSectionIndex/>}/>*/}

                                    <Route path="/healings" element={<HealingsSectionIndex/>}>
                                        <Route path=":id" element={<HealingOverview/>}>
                                            <Route path="overview" element={<HealingDetailsTab/>}>
                                                <Route path="" element={<HealingDetailsView/>}/>
                                                <Route path="edit" element={<HealingDetailsEditView/>}/>
                                            </Route>
                                            <Route path="documents" element={<HealingDocumentsTab/>}/>
                                            <Route path="booking-history" element={<HealingHistoryTab/>}/>
                                        </Route>
                                    </Route>

                                    <Route path="/classes" element={<ClassesSectionIndex/>}>
                                        <Route path=":id" element={<ClassOverview/>}>
                                            <Route path="overview" element={<ClassDetailsTab/>}>
                                                <Route path="" element={<ClassDetailsView/>}/>
                                                <Route path="edit" element={<ClassDetailsEditView/>}/>
                                            </Route>
                                            <Route path="documents" element={<ClassDocumentsTab/>}/>
                                            <Route path="schedule" element={<ClassSchedulingTab/>}>
                                                <Route path="" element={<ClassScheduleList/>}/>
                                                <Route path=":classScheduleId" element={<ClassScheduleDetails/>}/>
                                            </Route>
                                            <Route path="booking-history" element={<ClassHistoryTab/>}/>
                                        </Route>
                                    </Route>

                                    <Route path="/settings" element={<SettingsSectionIndex/>}>
                                        <Route path="general" element={<GeneralSettingsTab/>}/>
                                        <Route path="packages" element={<PackageSettingsTab/>}/>
                                        <Route path="locations" element={<LocationSettingsTab/>}/>
                                        <Route path="data" element={<DataSettingsTab/>}/>
                                    </Route>
                                </Route>
                                <Route path="*" element={<NotFound/>}/>
                            </Route>
                        </Routes>
                    </Suspense>
                </BrowserRouter>
            </QueryClientProvider>
        </HelmetProvider>
    );
}


const RootRoute = ({children}: { children: ReactNode }) => {
    const {isAuthenticated} = useAuth();
    const initHasRun = localStorage.getItem(INIT_HAS_RUN) === "1";

    // console.log("env:", process.env.REACT_APP_ENVIRONMENT);

    if (!isAuthenticated) {
        if (window.location.pathname === AUTH_PATH) {
            return <>{children}</>;
        }

        console.debug('[NOT AUTHENTICATED]: Redirect to "login"');

        // It can happen that the RootRoute renders when we already are rendering Login, which would cause and endless url of returnUrls; So return early if it is the login already
        if (window.location.pathname === AUTH_PATH) {
            return <></>;
        }

        return <Navigate to="/auth"/>;
    }

    if (initHasRun) {
        return <GuideFlowProvider>{children}</GuideFlowProvider>;
    }

    return <GuideFlowProvider><WelcomeScreen/></GuideFlowProvider>;

};

export default App;
