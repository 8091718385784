import {TargetDrive} from '@youfoundation/js-lib/core';

const driveType: string = "f1384137c8eb4f01b6fc145b583e8210";

interface GuideFlowDataDrive {
    drive: TargetDrive,
    name: string,
    description: string
}

const devDriveDefinitions: GuideFlowDataDrive = {
    name: "Guideflow Data R22.1",
    drive: {
        alias: "4499979500c24beab5cf91dd17937773",
        type: driveType
    },
    description: "GuideFlow Data drive"
}

const demoDriveDefinition: GuideFlowDataDrive = {
    name: "Guideflow Data r22.2",
    drive: {
        alias: "2219979500c24beab5cf91dd17937799",
        type: driveType
    },
    description: "GuideFlow Data drive"
}

const productionDriveDefinition: GuideFlowDataDrive = {
    name: "Guideflow Data",
    drive: {
        alias: "3399979500c24beab5cf91dd17937771",
        type: driveType
    },
    description: "GuideFlow Data drive"
}

export const getDataDrive = (): GuideFlowDataDrive => {
    const envName = process.env.REACT_APP_ENVIRONMENT;

    switch (envName.toLowerCase()) {
        case "production":
            return productionDriveDefinition;

        case "demo":
            return demoDriveDefinition;

        default:
            return devDriveDefinitions;
    }
}

export class GuideFlowConfig {
    static readonly DataDrive: TargetDrive = {
        alias: getDataDrive().drive.alias,
        type: getDataDrive().drive.type,
    };
}
