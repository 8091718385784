import {useGuideFlowProviderContext} from "../../../../../GuideFlowProviderContext";
import {Controller, useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Field, Label} from "../../../../controls/tailwindui/fieldset";
import {Button} from "../../../../controls/tailwindui/button";
import {CassieAwareSubmitButton} from "../../../../controls/vidence/cassie/CassieAwareSubmitButton";
import {CassieOfflineBanner} from "../../../../controls/vidence/cassie/CassieOfflineBanner";
import {ContactProgressionEventForm} from "./FormTypes";
import LocationSelector from "../../../../controls/gf/LocationSelector";
import {ContactProgressionEvent} from "../../../../../providers/contact/ContactProgressionEventProvider";
import toast from "react-hot-toast";
import {SECTION_ROOT} from "../../FormTypes";
import {dateTextToUtcSeconds, utcSecondsToDateText} from "../../../../../helpers/Dates";
import ClassSelector from "../../../../controls/gf/ClassSelector";
import {ClassDefinition} from "../../../../../providers/definitions/ClassDefinitionProvider";
import {LocationDefinition} from "../../../../../providers/definitions/LocationDefinitionProvider";

export default function ContactProgressionAddEditClass() {

    const {id, pid} = useParams();
    const context = useGuideFlowProviderContext();
    const {control, reset, handleSubmit, register} = useForm<ContactProgressionEventForm>();
    const [isSaving, setIsSaving] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (pid) {
            context.Contacts.ProgressionEvents.get(pid).then(evnt => {
                context.ClassDefinitions.getList([evnt.definitionId]).then(list => {
                    reset({
                        definition: list,
                        eventDate: utcSecondsToDateText(evnt.eventDateUtcSeconds),
                        ...evnt,
                    });
                });
            });
        }
    }, [pid, id, reset, context.Contacts.ProgressionEvents, context.Contacts.ProgressionEvents.StateChanged, context.ClassDefinitions, context.ClassDefinitions.StateChanged]);

    const handleLocationChange = (value: LocationDefinition, field: any) => {
        field.onChange(value.id);
    }

    const handleClassChange = (value: ClassDefinition[], field: any) => {
        field.onChange(value);
    }

    const onSubmit = async (formData: ContactProgressionEventForm) => {

        setIsSaving(true);

        const item: ContactProgressionEvent = {
            ...formData,
            contactId: id,
            eventDateUtcSeconds: dateTextToUtcSeconds(formData.eventDate),
            definitionType: "class",
            definitionId: formData.definition[0].id,
            locationId: formData.locationId
        };

        await context.Contacts.ProgressionEvents.save(item);
        toast.success("Class Attendance Added!", {icon: "🪄"});
        setIsSaving(false);
        navigate(`${SECTION_ROOT}/${id}/progression`);
    };

    return (
        <div>
            <CassieOfflineBanner/>
            <div className="p-3">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="xl:gap-x-16">
                        <div>
                            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                <div>
                                    <Field>
                                        <Label>Class</Label>
                                        {/*<Input {...register("", {required: true,})} invalid={errors?.title != null} autoFocus={true}/>*/}
                                        {/*{errors?.title && <ErrorMessage>*{errors.title.message}</ErrorMessage>}*/}

                                        <Controller
                                            name="definition"
                                            control={control}
                                            defaultValue={undefined}
                                            rules={{required: true}}
                                            render={({field}) => {
                                                return <ClassSelector
                                                    allowMultiple={false}
                                                    selection={field.value?.map((v: any) => v as ClassDefinition) ?? []}
                                                    onSaveSelection={(value) => handleClassChange(value, field)}
                                                />
                                            }}
                                        />
                                    </Field>
                                </div>
                                <div className="sm:col-span-2">
                                    <Field>
                                        <Label>Location</Label>
                                        <Controller
                                            name="locationId"
                                            control={control}
                                            defaultValue={null}
                                            render={({field, fieldState, formState,}) => (
                                                <LocationSelector selectedLocationId={field.value}
                                                                  onSaveSelection={(value) => handleLocationChange(value, field)}/>
                                            )}
                                        />
                                    </Field>
                                </div>
                                <div className="sm:col-span-2">
                                    <Field>
                                        <Label>Date/Time</Label>
                                    </Field>
                                    <input aria-label="Date and time" {...register("eventDate", {required: true})} type="date"/>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <Button type="button" disabled={isSaving} onClick={() => navigate("../")} outline={true}>
                            Cancel
                        </Button>
                        <CassieAwareSubmitButton isSaving={isSaving}/>
                    </div>
                </form>
            </div>
        </div>
    )
}