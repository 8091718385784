import {DotYouClient} from "@youfoundation/js-lib/core";
import {makeAutoObservable, runInAction} from "mobx";
import {HomebaseStorage, SaveFileConfig, SaveOptions} from "../core/storage/HomebaseStorageTypes";
import {GuideFlowConfig} from "../../system/GuideFlowConfig";
import {SimplifiedStorageProvider} from "../core/storage/SimplifiedStorageProvider";
import {INotificationHandler, notificationHandler} from "mediatr-ts";
import {ProgressionEventChanged} from "../Mediator/Mediator";
import {ContactProvider} from "../contact/ContactProvider";
import {humperdink} from "../../components/Sections/Contact/data";
import {normalizeGuid} from "../DataHelpers";
import {CordCutting, LifeActivation} from "../definitions/builtin/BuiltInHealings";

const fileConfig: SaveFileConfig = {
    dataType: 1033,
    fileType: 1033,
    targetDrive: GuideFlowConfig.DataDrive
}

type RecommendationType = "recent-activity" | "completed-healing" | "completed-class";

export interface Recommendation extends HomebaseStorage {
    id: string,
    contactId: string,
    reason: string,
    type: RecommendationType
}

export interface NextStepRecommendation extends HomebaseStorage {
    id: string,
    contactId: string,
    healingId: string,
    classId: string
}

export interface FollowUpRecommendation extends HomebaseStorage {
    id: string,
    contactId: string,
    subject: string;
    reason: "birthday" | "checkin"
}

const recentActivityGroupId: string = "6d09ed82-d3ae-42c1-b5a6-37cfbbe631b8";
const completedHealingGroupId: string = "db9f1434-b82b-4565-a19c-46ef7ae4498f";
const completedClassGroupId: string = "cdaa3ea0-a775-4737-b4b7-b1ed40be260c";

@notificationHandler(ProgressionEventChanged)
export class RecommendationProvider implements INotificationHandler<ProgressionEventChanged> {
    private static instance: RecommendationProvider
    private readonly provider: SimplifiedStorageProvider<Recommendation>;
    private readonly dotYouClient: DotYouClient;
    public StateChanged: number = 0;

    // public static FileConfig: SaveFileConfig = fileConfig;

    constructor(config: SaveFileConfig, dotYouClient: DotYouClient) {
        this.dotYouClient = dotYouClient;
        this.provider = new SimplifiedStorageProvider<Recommendation>(config, dotYouClient);
        makeAutoObservable(this);
    }

    static getInstance(dotYouClient: DotYouClient) {
        if (RecommendationProvider.instance == null) {
            RecommendationProvider.instance = new RecommendationProvider(fileConfig, dotYouClient);
        }
        return RecommendationProvider.instance;
    }

    public async getContactsForFollowup(): Promise<FollowUpRecommendation[]> {
        // how do followups get into the system?
        // 1. a contact is matched with a 'next check in date
        // birthdate coming soon

        const c = await ContactProvider.getInstance(this.dotYouClient).getAll();
        if (c && c.length > 0) {
            const fr1: FollowUpRecommendation = {
                id: "77f84afe-19e8-4e63-a22b-5cdba07e2387",
                contactId: c[1].id,
                reason: undefined,
                subject: "General Checkin"
            }

            const fr2: FollowUpRecommendation = {
                id: "87584afe-19e8-4e63-a22b-5cdba07e2387",
                contactId: c[3].id,
                reason: undefined,
                subject: "Birthday"
            }

            return [fr1, fr2];
        }
        return [];
    }

    public async getNextSteps(): Promise<NextStepRecommendation[]> {
        const c = await ContactProvider.getInstance(this.dotYouClient).getAll();
        if (c && c.length > 0) {

            const nr1: NextStepRecommendation = {
                id: "53f84afe-19e8-4e63-a22b-5cdba07e2387",
                contactId: "e9b947bb10f2f338d1b5b2158eafb35e",
                healingId: LifeActivation.id,
                classId: ""
            };

            const nr2: NextStepRecommendation = {
                id: "13c6ec76-663a-4e42-b4eb-00c41f2c8b28",
                contactId: normalizeGuid(humperdink.id),
                healingId: CordCutting.id,
                classId: ""
            };

            return [nr1, nr2];

        }
        return [];
    }

    public async getClassRecommendations(classDefinitionId: string): Promise<Recommendation[]> {

        const results = await this.provider.getAll<Recommendation>({
            groupId: [this.getGroupId("completed-class"), this.getGroupId("recent-activity")],
        });

        return results;
        // Recommendations are based on the following
        // 1. prerequisite classes (the contact has taken the prereq class)
        // 2. recent activity (received a healing; a note was added)
    }

    public async save(recommendation: Recommendation): Promise<any> {

        const options: SaveOptions = {
            groupId: this.getGroupId(recommendation.type),
            tags: []
        }

        await this.provider.saveHeaderFile(recommendation, undefined, options);
    }

    public async expire(recommendationId: string): Promise<any> {
        await this.provider.hardDeleteFile(recommendationId);
    }

    //

    private getGroupId(type: RecommendationType) {
        switch (type) {
            case "recent-activity":
                return recentActivityGroupId;

            case "completed-healing":
                return completedHealingGroupId;

            case "completed-class":
                return completedClassGroupId;
        }
    }

    private notifyStateChange() {
        runInAction(() => {
            this.StateChanged++;
        });
    }

    // mediator handlers
    // async handle(notification: ProgressionEventAdded): Promise<void> {
    //     //add new recommendation
    //     console.log('notification:', notification)
    // }


    async handle(notification: ProgressionEventChanged): Promise<void> {
        //add new recommendation
        console.log('notification:', notification);
    }
}